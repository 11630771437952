const speechData = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/-UTQhJ_7Ev8?si=hGvE5aITC4QUMBFm",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/QPyKtk9U9C4?si=fLV62smUG_hQt800",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/yOVTyBHQI_A?si=cQcTr7802JDChG44",
  },
];

export default speechData;
