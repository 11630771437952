import React from "react";
import Welcome from "./screens/Welcome";
import Initiative from "./screens/Initiative";
import Profession from "./screens/Profession";
import Csr from "./screens/Csr";
import Sci from "./screens/Sci";
import GetInvolved from "./screens/GetInvolved";
import Hero from "../components/Hero";
import News from "./screens/News";

const Home = () => {
  return (
    <>
      <Hero />
      <div className="mt-4">
        <Welcome />
        <Initiative />
        <Profession />
        <Csr />
        <Sci />
        <News />
        <GetInvolved />
      </div>
    </>
  );
};

export default Home;
