import React from "react";

const Welcome = () => {
  return (
    <div className="welcome pt-md-4 ">
      <div className="row ">
        <div className="col-md-5 pe-4 pt-3 pe-md-0 pt-lg-0 ">
          <div className="video pt-md-5 mt-md-4 ">
            <iframe
              className="w-100 youtube"
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fbishal.gaire.16%2Fvideos%2F757977629123592%2F&show_text=false&width=560&t=0"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
            />
          </div>
        </div>
        <div className="col-md-7 p-4 pt-0 pt-md-4 ">
          <div className="text ps-md-3 ps-md-0">
            <h2 className="pb-3">About Bishal</h2>
            <p className="p-2 pb-0">
              Bishal Gaire was born in a middle-class family of Kapilvastu
              district in Western Nepal. Having studied in government school
              till grade 12, he always involved himself in leadership roles, be
              it in the Child-clubs in school days or through various youth
              organizations during his adolescent period. Being well versed in
              communication, he is actively worked in community radio,
              presenting news and hosting radio programs which led him to gain
              10 years of work experience in radio, television, and online media
              from the age of 18. <br />
              Being devoted to youth leadership, political literacy, innovation
              and governance over the past decade, he is completed a master’s
              degree in Public Policy, Governance, and Anti-Corruption from
              Tribhuvan University...
            </p>
            <div className="button pb-2">
              <a href="#agenda" className="ms-3">
                <button>READ MORE</button>
              </a>
              <button className="ms-3 mt-3 mt-md-0 mt-lg-0">
                DOWNLOAD VITA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
