import React from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import speechData from "../../data/SpeechData";
import campiagnData from "../../data/CampiagnData";
import interviewData from "../../data/InterviewData";
const Sci = () => {
  return (
    <div className="sci">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="flex-row">
          <Nav.Item>
            <Nav.Link eventKey="first">Speech</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">Campiagn</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="third">Interviews</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="row pt-3 pt-lg-4 ">
                {speechData &&
                  speechData.map((s) => (
                    <div className="col-md-4 p-3" key={s.id}>
                      <iframe
                        className="w-100"
                        height={250}
                        src={s.src}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen=""
                      />
                    </div>
                  ))}
              </div>
              <div className="view_more pb-4 pt-3">
                <button>View More</button>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="row pt-3 pt-lg-4">
                {campiagnData &&
                  campiagnData.map((c) => (
                    <div className="col-md-4 p-3" key={c.id}>
                      <iframe
                        className="w-100"
                        height={250}
                        src={c.src}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen=""
                      />
                    </div>
                  ))}
              </div>
              <div className="view_more pb-4 pt-3">
                <button>View More</button>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className="row pt-3 pt-lg-4">
                {interviewData &&
                  interviewData.map((i) => (
                    <div className="col-md-4 p-3" key={i.id}>
                      <iframe
                        className="w-100"
                        height={250}
                        src={i.src}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen=""
                      />
                    </div>
                  ))}
              </div>
              <div className="view_more pb-4 pt-3">
                <button>View More</button>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Sci;
