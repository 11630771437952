import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { FaFacebookF, FaTwitter } from "react-icons/fa";

const Csr = () => {
  return (
    <div className="crs mb-5 mt-5 pt-lg-4 pb-lg-4">
      <h2 className="mb-3">PHILANTHROPIST</h2>
      <div className="crss">
        <Carousel>
          <Carousel.Item className="crs-flex">
            <div className="image">
              <img src="./images/rclug.jpg" alt="" />
            </div>
            <div className="content">
              <h3> Lorem ipsum dolor sit amet consectetur adipisicing elit</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Provident temporibus voluptates eum voluptatibus voluptatum
                ratione harum similique necessitatibus deleniti recusandae,
                dolor, qui quibusdam possimus ...
              </p>
              <button>Read more</button>
              <div className="crsicon">
                <div className="icon">
                  <FaFacebookF className="cicon" />
                </div>
                <div className="icon">
                  <FaTwitter className="cicon" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="crs-flex">
            <div className="image">
              <img src="./images/rclug.jpg" alt="" />
            </div>
            <div className="content">
              <h3> Lorem ipsum dolor sit amet consectetur adipisicing elit</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Provident temporibus voluptates eum voluptatibus voluptatum
                ratione harum similique necessitatibus deleniti recusandae,
                dolor, qui quibusdam possimus ...
              </p>
              <button>Read more</button>
              <div className="crsicon">
                <div className="icon">
                  <FaFacebookF className="cicon" />
                </div>
                <div className="icon">
                  <FaTwitter className="cicon" />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Csr;
