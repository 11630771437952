import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const Initiative = () => {
  return (
    <div className="init p-2 pt-5 pb-5">
      <div className="head">
        <h2 className="p-3">Leadership For Prosperity</h2>
      </div>
      <div className="initiative mt-lg-4 pb-lg-5">
        <Carousel>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 pe-md-3 pt-md-3">
                <h3>Startup Fellowship</h3>
                <p>
                  Bishal Gaire initiated a campaign named Startup Fellowship
                  Program to scale up startups, innovation, and youth
                  entrepreneurship in Nepal. Initially in the year 2020, the
                  program commenced with the name “Startup Idea Call Program”
                  under Bishal Foundation, which was later renamed into the
                  Startup Fellowship Program. This fellowship program started
                  with the motive of providing training and mentorship,
                  industrial visits, incubation, seed funding, competitive cash
                  awards, investment assurance, legal facilitation, and exposure
                  through television programs for young entrepreneurs and their
                  startup ideas. In order to promote financial literacy, expand
                  access to finance, and create self- employment...
                </p>
                <div className="button pt-md-4">
                  <Link to="/startup-fellowship">
                    <button>READ MORE</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 ps-md-3">
                <div className="imageSlider mt-3 mt-md-0 mt-lg-0">
                  <img src="./images/startf1.jpg" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 pe-md-3 pt-md-3">
                <h3>Governance Campaign</h3>
                <p>
                  Holding a Masters degree in Public Policy, Governance, and
                  Anti-Corruption from Tribhuvan University, Bishal Gaire has
                  been advocating for Governance friend policies, its programs
                  and proper budget allocation. He has engaged himself in
                  academic studies, research, policy analysis, participatory
                  dialogues related to Governance. He even mentored in the
                  publication of governance reports and performed consulting
                  works in the realm of Governance. Serving as the President of
                  the International Governance Institute, he has been actively
                  advocating to control corruption through various national and
                  international forums. He believes that in the context of
                  Nepal&#39;s development and sustainability...
                </p>
                <div className="button pt-md-4">
                  <Link to="/governance-campaign">
                    <button>READ MORE</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 ps-md-3">
                <div className="imageSlider mt-3 mt-md-0 mt-lg-0">
                  <img src="./images/gov.jpg" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 pe-md-3 pt-md-3">
                <h3>Innovation for Political Reform</h3>
                <p>
                  Being actively engaged in the student union from the age of 15
                  years, Bishal Gaire involved himself in the Jana Andolan of
                  year 2062/63 B.S. from his district. Even though he worked as
                  a central leader for the student union for a whole decade, he
                  is not affiliated with any political party lately. He strongly
                  believes in the need of political awareness and transformation
                  in youth’s thinking, energy, commitment, and time to
                  contribute to the nation-building process. With the belief
                  that a change in the currently leading political party or a
                  new alternative politics can bring about economic, social, and
                  cultural transformation in the country, he is engaged in
                  studying, researching, and publishing articles...
                </p>
                <div className="button pt-md-4">
                  <Link to="/innovation-for-political-reform">
                    <button>READ MORE</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 ps-md-3">
                <div className="imageSlider mt-3 mt-md-0 mt-lg-0">
                  <img src="./images/pr.jpg" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 pe-md-3 pt-md-3">
                <h3>Entrepreneurship Campaign</h3>
                <p>
                  Bishal Gaire is actively involved in the development and
                  enhancement of entrepreneurship, entrepreneurs, and
                  entrepreneurialism. He has been making Constant efforts to
                  attract and engage young minds in entrepreneurship, and has
                  been lobbying to provide mature entrepreneurs and businesses
                  with continuous support for business expansion, conservation,
                  and strategic improvements. As the Executive Chairperson of
                  Nepal Business Institute, he has been consistently involved in
                  inspiring the private sector at the local, provincial, and
                  federal levels through initiatives such as entrepreneurship
                  training, organizing summits, financial literacy, video
                  exhibitions, entrepreneurial programs...
                </p>
                <div className="button pt-md-4">
                  <Link to="/entrepreneurship-campaign">
                    <button>READ MORE</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 ps-md-3">
                <div className="imageSlider mt-3 mt-md-0 mt-lg-0">
                  <img src="./images/ent.jpg" className="w-100" alt="" />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Initiative;
