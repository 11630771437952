import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../image/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Navbar collapseOnSelect expand="lg" className="nav">
        <Link to="/" className="text-decoration-none">
          <Navbar.Brand className="left_logo">
            <div className="flag">
              <img src={logo} alt="" />
            </div>
            <div className="ps-3">
              <h3>Bishal Gaire</h3>
              <p className="lthe">Leadership For Prosperity</p>
            </div>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Link to="/about" className="text-decoration-none">
              <li className="nav_link">
                About Bishal
                <ul className="nav_dropdown intro">
                  <li className="drop_link">Introduction</li>
                  <li className="drop_link">Biography</li>
                  <li className="drop_link">Leadership Journey</li>
                </ul>
              </li>
            </Link>
            <li className="nav_link">
              Inititative
              <ul className="nav_dropdown">
                <Link
                  to="/startup-fellowship"
                  className="text-decoration-none text-white"
                >
                  <li className="drop_link">Startup Fellowship</li>
                </Link>
                <Link
                  to="/governance-campaign"
                  className="text-decoration-none text-white"
                >
                  <li className="drop_link">Governance Campaign</li>
                </Link>
                <Link
                  to="/innovation-for-political-reform"
                  className="text-decoration-none text-white"
                >
                  <li className="drop_link">Innovation for Political Reform</li>
                </Link>
                <Link
                  to="/entrepreneurship-campaign"
                  className="text-decoration-none text-white"
                >
                  <li className="drop_link">Entrepreneurship Campaign</li>
                </Link>
              </ul>
            </li>
            <li className="nav_link">
              profession
              <ul className="nav_dropdown mini">
                <li className="drop_link">Entrepreneur</li>
                <li className="drop_link">Mentor/Consultant</li>
                <li className="drop_link">TV Presenter/Moderator</li>
                <li className="drop_link">Literary Figure</li>
              </ul>
            </li>
            <li className="nav_link">
              leadership
              <ul className="nav_dropdown ">
                <a
                  href="https://www.nepalbusinessinstitute.com/"
                  target="__blank"
                  className="text-white text-decoration-none"
                >
                  <li className="drop_link">Nepal Business Institute</li>
                </a>
                <li className="drop_link">IGI</li>
                <a
                  href="https://www.bishalfoundation.org/"
                  target="__blank"
                  className="text-white text-decoration-none"
                >
                  <li className="drop_link">Bishal Foundation</li>
                </a>
                <a
                  href="https://www.nyesummit.org/"
                  className="text-white text-decoration-none"
                  target="__blank"
                >
                  <li className="drop_link">NYE Summit</li>
                </a>
                <a
                  href="https://nepalbusinesssummit.com/"
                  className="text-white text-decoration-none"
                  target="__blank"
                >
                  <li className="drop_link">Nepal Business Summit</li>
                </a>
                <li className="drop_link">Startup Fellowship Program</li>
                <a
                  href="https://nepalbusiness.com/"
                  className="text-white text-decoration-none"
                  target="__blank"
                >
                  <li className="drop_link">NepalBusiness.com</li>
                </a>
              </ul>
            </li>
            <li className="nav_link">
              Philanthropist
              <ul className="nav_dropdown mini">
                <li className="drop_link">Bishal Foundation</li>
                <li className="drop_link">Rotary Club</li>
              </ul>
            </li>
            <li className="nav_link">
              gallery
              <ul className="nav_dropdown mini">
                <li className="drop_link">Photos</li>
                <li className="drop_link">Videos</li>
                <li className="drop_link">News</li>
              </ul>
            </li>
            <Link to="/contact" className="text-decoration-none">
              <li className="nav_link">contact me</li>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
