import React from "react";

const Loading = () => {
  return (
    <div className="loading">
      <div>
        <center>
          <img src="./images/logo.png" alt="" />
        </center>
        <div className="pt-5">
          <h2>
            Welcome To <span>bishalgaire.com</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Loading;
