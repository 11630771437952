import React, { useState } from "react";
import Slider from "react-slick";

const Profession = () => {
  const [showContent, setShowContent] = useState({
    section1: false,
    section2: false,
    section3: false,
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoPlay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  const toggleContent = (section) => {
    setShowContent((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="profession pt-4 pb-5">
      <div className="head">
        <h2 className="p-3">Profession</h2>
      </div>
      <Slider {...settings} className="pb-4">
        <div className="col-md-4">
          <div className="ecard">
            <h3>Entrepreneur</h3>
            <p>
              Bishal Gaire has established himself as a rising youth
              entrepreneur in Nepal. He has been actively involved in diverse
              sectors including Media, Education, IT, Agriculture along with
              various startup businesses and is actively engaged in both
              production and service-oriented ventures.
              {showContent.section1 && (
                <span>
                  Currently, he holds the leadership role of a Chairperson in
                  prominent organizations like Nepal Business Institute, Bishal
                  Media Group, NepalBusiness.com, and Bishal Foundation. He
                  brings extensive experience and a strong passion for risk
                  management, brand development, marketization, and maintaining
                  powerful public relations within the entrepreneurial sector.
                </span>
              )}
            </p>
            <div className="button pt-md-4">
              <button onClick={() => toggleContent("section1")}>
                {showContent.section1 ? "READ LESS" : "READ MORE"}
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="ecard">
            <h3>Mentor/ Consultant</h3>
            <p>
              Bishal Gaire has been mentoring and giving advisory services in
              fields including Startup, Entrepreneurship, Leadership
              Development, Corporate Governance, News Anchoring, Television
              program presenter as well as program management and other related
              areas.
              {showContent.section2 && (
                <span>
                  He has shared his learnings and experiences to the targeted
                  beneficiaries to various government organizations, Banks and
                  Financial Institutions, College that offers business studies,
                  Radio and Televisions, Rotary clubs along with various other
                  social organizations. He is a passionate enthusiast when it
                  comes to sharing his knowledge and experiences.
                </span>
              )}
            </p>
            <div className="button pt-md-4">
              <button onClick={() => toggleContent("section2")}>
                {showContent.section2 ? "READ LESS" : "READ MORE"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="ecard">
            <h3>TV Presenter/Moderator</h3>
            <p>
              Being actively involved in Radio and Journalism from the age of
              17, Bishal Gaire is passionate about sharing his learning and
              imparting experiences to new individuals.
            </p>

            <div className="button pt-md-4">
              <button onClick={() => toggleContent("section3")}>
                {showContent.section3 ? "READ LESS" : "READ MORE"}
              </button>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Profession;
