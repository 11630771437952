import React from "react";

const News = () => {
  return (
    <div>
      <div className="news-room  pt-5 ps-md-0 ps-3 pb-5">
        <div className="head">
          <h2 className="pt-md-3">Latest News</h2>
        </div>
        <div className="row w-100 ps-md-5 pe-md-5 pt-5">
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/new01.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>२०८० जेष्ठ २३ गते </i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  नेपाल बिजनेस समिट: १९ बुँदे घोषणापत्र सार्वजनिक
                  (घोषणापत्रसहित)
                </h6>
                <p className="text">
                  काठमाडौं । बैंक तथा वित्तीय संस्था परिसंघ नेपाल (सिबिफिन) र
                  नेपाल बिजनेश इन्ष्टिच्यूट (एनबिआई)ले आयोजना गरेको नेपाल बिजनेस
                  समिट– २०८० ले १९ बुँदे घोषणापत्र जारी गरेको छ ...
                </p>
                <a
                  href="https://clickmandu.com/2023/05/245356.html?fbclid=IwAR25eDs5kEYieQVla_ALyFgUOaE84ftZT0lT1EdaVWNiWOVXrEWiH6GQ9AI"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/new02.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>May 3, 2023</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  Nepal Business Summit 2023 to be held on
                </h6>
                <p className="text">
                  he Government of Nepal, Confederation of Banks and Financial
                  Institutions Nepal (CBFIN) and Nepal Business Institute will
                  jointly organise Nepal Business Summit 2023 in Kathmandu, on
                  May 7.Prime Minister ...
                </p>
                <a
                  href="https://b360nepal.com/nepal-business-summit-2023-to-held-on-may-7/?fbclid=IwAR0OMe5IJvgm1LY1wVsI8x1RW-XD0sWs-gM2dIUzRD8zpCsOZ-VZ9DW_jag"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="news-card">
              <div className="news-image">
                <img
                  src="./images/new03.jpg"
                  alt="..."
                  height={270}
                  className="w-100"
                />
              </div>
              <div className="news-content">
                <p className="date">
                  <i>९ फागुन, २०७८</i>
                </p>
                <h6 style={{ fontWeight: "bold" }}>
                  सातै प्रदेशमा ‘प्रादेशिक उद्यमशिलता परिसंवाद’ आयोजना हुँदै
                </h6>
                <p className="text">
                  प्रदेश सरकार, नेपाल उद्योग परिसंघ र विशाल फाउन्डेसनको संयुक्त
                  आयोजनामा प्रादेशिक उद्यमशिलता परिसंवाद (Provincial
                  Entrepreneurship Dialogue) हुने भएको छ । परिसंवादमा व्यवसायका
                  ...
                </p>
                <a
                  href="https://www.nepalbusiness.com/News/Details/2355?fbclid=IwAR3l77bLWgG_4pAkBzosYFZGnK2Nz_4jGZXHivGrN7DG5b0qAE4NDz5-y3U"
                  target="__blank"
                  className="text-decoration-none"
                >
                  <h5>Read More</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="view_more pt-3">
          <button>View More</button>
        </div>
      </div>
    </div>
  );
};

export default News;
