import React from "react";

const GovCampg = () => {
  return (
    <div className="about pt-5 pb-5">
      <div className="about-head pt-5">
        <h3>Governance Campaign</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6 pt-lg-3">
            <p>
              Holding a Masters degree in Public Policy, Governance, and
              Anti-Corruption from Tribhuvan University, Bishal Gaire has been
              advocating for Governance friend policies, its programs and proper
              budget allocation. He has engaged himself in academic studies,
              research, policy analysis, participatory dialogues related to
              Governance. He even mentored in the publication of governance
              reports and performed consulting works in the realm of Governance.
              <br />
              Serving as the President of the International Governance
              Institute, he has been actively advocating to control corruption
              through various national and international forums. He believes
              that in the context of Nepal&#39;s development and sustainability,
              there has always been petty corruption at the grassroots levels
              whereas grand and policy corruption at the higher level. Thus, he
              aims to end corruption and raise awareness about it to the general
              public.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <img src="./images/gov.jpg" className="w-100" alt="" />
          </div>
        </div>
        <p></p>
      </div>
    </div>
  );
};

export default GovCampg;
