import React from "react";
import Carousel from "react-bootstrap/Carousel";
import carouselData from "../data/CarouselData";

const Hero = () => {
  return (
    <div className="carousel_hero mt-2 mb-5">
      <Carousel>
        {carouselData &&
          carouselData.map((c) => (
            <Carousel.Item key={c.id}>
              <img src={c.image} className="w-100" alt="" />
              <div className="caption">
                <Carousel.Caption>
                  <p>{c.short_description}</p>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
};

export default Hero;
