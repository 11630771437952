const interviewData = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/WjuzgIATsN4?si=_RLsFZMuzOuBOSTP",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/abcjov-mDAU?si=4oYW8zNaU6ydbTF1",
  },
];

export default interviewData;
