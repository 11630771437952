import React from "react";

const Contact = () => {
  return (
    <div className="contact_section pt-5 pb-5">
      <div className="contact_head pt-5 pb-5">
        <h2>SEND ME A MESSAGE</h2>
      </div>
      <div className="contact_form">
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="input_contact_label">
                <label htmlFor="name">NAME*</label>
                <input type="text" className="w-100" />
              </div>
              <div className="input_contact_label">
                <label htmlFor="name">EMAIL*</label>
                <input type="text" className="w-100" />
              </div>
              <div className="input_contact_label">
                <label htmlFor="name">SUBJECT*</label>
                <input type="text" className="w-100" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input_contact_label">
                <label htmlFor="name">MESSAGE*</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="9"
                  className="w-100"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="contact_button mt-3">
            <button>Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
