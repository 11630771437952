const carouselData = [
  {
    id: 1,
    image: "./images/new.jpg",
    short_description:
      "Bishal Gaire, an Entrepreneur, a Philanthropist and an activist working for good governance to make a secure and prosperous country.",
  },
  {
    id: 2,
    image: "./images/prachanda.jpg",
    short_description:
      "Discussion on Youth Entrepreneurship with Rt Hon’ble Pushpa Kamal Dahal, the Prime Minister of Nepal.",
  },
  {
    id: 3,
    image: "./images/3.jpg",
    short_description:
      "Bishal Gaire represents youth entrepreneurs amidst senior entrepreneurs and high-level officials of Nepal.",
  },
  {
    id: 4,
    image: "./images/9.jpg",
    short_description:
      "The president of NBI, Mr. Bishal Gairethe sharing stage with Rt. Hon’ble Pushpa Kamal Dahal, the Prime Minister of Nepal along with the Ambassador of United States to Nepal, His Excellency Dean R.Thompson in the inauguration session of the Third Nepal Youth Entrepreneurship Summit 2023.",
  },
  {
    id: 5,
    image: "./images/5.jpg",
    short_description:
      "With the Hon’ble KP Sharma Oli, Immediate Past Prime Minister of Nepal.",
  },
  {
    id: 6,
    image: "./images/6.jpg",
    short_description:
      "On a discussion program on ‘Private sector of Nepal’ with the Hon’ble Bidya Devi Bhandari, Immediate Past President of Nepal in Rashtrapati Bhawan.",
  },
  {
    id: 7,
    image: "./images/7.jpg",
    short_description:
      "A team including Hon’ble Chakrapani Khanal Baldev, Former Minister of Agriculture and representatives from Heifer International with the women entrepreneurs of Lumbini Province.",
  },
  {
    id: 8,
    image: "./images/8.jpg",
    short_description:
      "On a significant discussion on Youth Entrepreneurship with the Ambassador of the United States to Nepal along with Senior entrepreneur, Dr. Upendra Mahato, CEO of RBB, Kiran Kumar Shrestha and the Director of Indriver Roman N. Ermoshin.",
  },
  {
    id: 9,
    image: "./images/4.jpg",
    short_description:
      "Bishal Gaire along with Rt. Hon’ble Pushpa Kamal Dahal, the Prime Minister of Nepal, Billionaire Entrepreneur Hon. Binod Chaudhary, Member of Parliament, the representatives of IFC The World Bank and the representatives of the European Union.",
  },
  {
    id: 10,
    image: "./images/10.jpg",
    short_description:
      "The representatives of 11 government and private sector organizations participated in a multi-party understanding agreement at the provincial level for the promotion of entrepreneurship and access to Finance.",
  },
];

export default carouselData;
