import React from "react";

const GetInvolved = () => {
  return (
    <div className="get_involved mb-4 pb-4">
      <div className="head pt-5 pb-3">
        <h2>Join Our Movement!</h2>
        <h3>We have dream. We will achieve prosperity!</h3>
      </div>
      <div className="join_form">
        <form className="form">
          <div className="input_check">
            <div className="input">
              <div className="input_label">
                <input
                  type="text"
                  placeholder="First Name *"
                  className="get"
                  required
                />
              </div>
              <div className="input_label">
                <input
                  type="text"
                  placeholder="Last Name *"
                  className="get"
                  required
                />
              </div>
              <div className="input_label">
                <input
                  type="text"
                  placeholder="Email Address *"
                  className="get"
                  required
                />
              </div>
              <div className="input_label">
                <input
                  type="text"
                  placeholder="Phone Number *"
                  className="get"
                  required
                />
              </div>
            </div>
            <div className="checkbox mb-2">
              <div className="input_label">
                <input type="checkbox" id="bishal" className="get" />
                <label htmlFor="bishal">I WANT TO ENDORSE BISHAL</label>
              </div>
              <div className="input_label ps-lg-2">
                <input type="checkbox" id="bishal2" className="get" />
                <label htmlFor="bishal2">I WANT TO VOLUNTEER</label>
              </div>
              <div className="input_label">
                <input type="checkbox" id="bishal1" className="get" />
                <label htmlFor="bishal1">I WANT EVENT UPDATES</label>
              </div>
              <div className="input_label ps-lg-2">
                <input type="checkbox" id="bishal3" className="get" />
                <label htmlFor="bishal3">BECOME A COMMUNITY CAPTAIN</label>
              </div>
            </div>
          </div>
          <div className="input_label ps-md-2 ms-md-1">
            <input type="checkbox" id="agree" />
            <label htmlFor="agree" className="agree">
              I AGREE TO RECEIVE TEXTS
            </label>
          </div>
          <div className="register_button mt-4">
            <button>Register</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GetInvolved;
