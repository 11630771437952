import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Loading from "./Loading";
import StartupF from "./pages/StartupF";
import GovCampg from "./pages/GovCampg";
import Ipr from "./pages/Ipr";
import EntCamp from "./pages/EntCamp";

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/startup-fellowship" element={<StartupF />} />
              <Route exact path="/governance-campaign" element={<GovCampg />} />
              <Route
                exact
                path="/innovation-for-political-reform"
                element={<Ipr />}
              />
              <Route
                exact
                path="/entrepreneurship-campaign"
                element={<EntCamp />}
              />
            </Routes>
            <Footer />
          </Router>
        </>
      )}
    </>
  );
};

export default App;
