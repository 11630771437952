import React from "react";
import { FaStar } from "react-icons/fa";

const About = () => {
  return (
    <div className="about pt-5 pb-5">
      <div className="about-head pt-5">
        <h3>
          Bishal Gaire
          <p>Leadership For Prosperity</p>
        </h3>
        <div className="star_icon ps-2">
          <FaStar className="star" />
          <FaStar className="star" />
          <FaStar className="star" />
          <FaStar className="star" />
          <FaStar className="star" />
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6">
            <p>
              Bishal Gaire was born in a middle-class family of Kapilvastu
              district in Western Nepal. Having studied in government school
              till grade 12, he always involved himself in leadership roles, be
              it in the Child-clubs in school days or through various youth
              organizations during his adolescent period. Being well versed in
              communication, he is actively worked in community radio,
              presenting news and hosting radio programs which led him to gain
              10 years of work experience in radio, television, and online media
              from the age of 18.
              <br />
              <br />
              Being devoted to youth leadership, political literacy, innovation
              and governance over the past decade, he is completed a master’s
              degree in Public Policy, Governance, and Anti-Corruption from
              Tribhuvan University. Lately, he has gained recognition as a youth
              entrepreneur, philanthropist, and an activist dedicated to promote
              good governance in Nepal. In the realm of entrepreneurship, he
              holds the position of Chairperson at Nepal Business Institute
              (NBI) while serving as the President of the International
              Governance Institute, working in the field of governance. He has a
              particular interest and involvement in journalism, literature,
              politics, startups, innovation, social campaigns and
              governance-friendly programs. Along with this, he is mostly seen
              in charitable activities through organizations like Bishal
              Foundation and Rotary Club.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <iframe
              className="w-100 a_video"
              src="https://www.youtube.com/embed/94dasVGSWYE?si=4TYy_ASctXLOMopy"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen=""
            />
          </div>
        </div>
        <p></p>
      </div>
    </div>
  );
};

export default About;
