import React from "react";

const EntCamp = () => {
  return (
    <div className="about pt-5 pb-5">
      <div className="about-head pt-5">
        <h3>Entrepreneurship Campaign</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6">
            <p>
              Bishal Gaire is actively involved in the development and
              enhancement of entrepreneurship, entrepreneurs, and
              entrepreneurialism. He has been making Constant efforts to attract
              and engage young minds in entrepreneurship, and has been lobbying
              to provide mature entrepreneurs and businesses with continuous
              support for business expansion, conservation, and strategic
              improvements.
              <br />
              As the Executive Chairperson of Nepal Business Institute, he has
              been consistently involved in inspiring the private sector at the
              local, provincial, and federal levels through initiatives such as
              entrepreneurship training, organizing summits, financial literacy,
              video exhibitions, entrepreneurial programs, social media
              campaigns, and policy dialogues. He has been playing a key role in
              keeping the government accountable and more responsible. He is
              vocal about advocating a self-reliant economy, friendly policies
              and programs for the private sector from the Government of Nepal,
              for the economic development of the country.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <img src="./images/ent.jpg" className="w-100" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntCamp;
