import React from "react";

const Ipr = () => {
  return (
    <div className="about pt-5 pb-5">
      <div className="about-head pt-5">
        <h3>Innovation for Political Reform</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6 pt-lg-3">
            <p>
              Being actively engaged in the student union from the age of 15
              years, Bishal Gaire involved himself in the Jana Andolan of year
              2062/63 B.S. from his district. Even though he worked as a central
              leader for the student union for a whole decade, he is not
              affiliated with any political party lately. He strongly believes
              in the need of political awareness and transformation in youth’s
              thinking, energy, commitment, and time to contribute to the
              nation-building process. <br />
              With the belief that a change in the currently leading political
              party or a new alternative politics can bring about economic,
              social, and cultural transformation in the country, he is engaged
              in studying, researching, and publishing articles in various
              journals about present politics. In the realm of politics, He has
              published a Kabitasangraha, a collection of poems titled “Utha
              Deshka Yuwa” and a Bichar Sagraha, a collection of thoughts titled
              “Yuwa ra Rajniti”. He has also contributed more than three dozen
              articles on politics and innovation, published in various online
              and print publications.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <img src="./images/pr.jpg" className="w-100" alt="" />
          </div>
        </div>
        <p></p>
      </div>
    </div>
  );
};

export default Ipr;
