const campiagnData = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/zlPXJgaWxoo?si=LaoPg_S4HhriM2SP",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/E4kZCdwYBVI?si=VPJjbJw27YFdEYjM",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/FF6veQcx91M?si=WKlw4ABYTp11jx6D",
  },
  {
    id: 4,
    src: "https://www.youtube.com/embed/N-q9MajSdTw?si=N-khcMTMMrMtA3OA",
  },
];

export default campiagnData;
