import React from "react";

const StartupF = () => {
  return (
    <div className="about pt-5 pb-5">
      <div className="about-head pt-5">
        <h3>Startup Fellowship</h3>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-6 pt-lg-3">
            <p>
              Bishal Gaire initiated a campaign named Startup Fellowship Program
              to scale up startups, innovation, and youth entrepreneurship in
              Nepal. Initially in the year 2020, the program commenced with the
              name “Startup Idea Call Program” under Bishal Foundation, which
              was later renamed into the Startup Fellowship Program. <br />
              This fellowship program started with the motive of providing
              training and mentorship, industrial visits, incubation, seed
              funding, competitive cash awards, investment assurance, legal
              facilitation, and exposure through television programs for young
              entrepreneurs and their startup ideas. In order to promote
              financial literacy, expand access to finance, and create self-
              employment opportunities for youth within the country, even the
              government and the private sector have been working in partnership
              through this Campaign.
            </p>
          </div>
          <div className="col-md-6 p-3">
            <img src="./images/startf1.jpg" className="w-100" alt="" />
          </div>
        </div>
        <p></p>
      </div>
    </div>
  );
};

export default StartupF;
